import * as CONSTRUCTOR from '../generate';
import * as Fragments from './fragments';
import * as NoteFragments from '../Note/fragments';

// READ read customer GraphQL schema.
export const READ = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getCustomer',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...CustomerFields}',
    fragments: [Fragments.customer],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// SEARCH search customer GraphQL schema.
export const SEARCH = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'searchCustomers',
    args: [
      { name: 'query', type: 'String' },
      { name: 'name', type: 'String' },
      { name: 'email', type: 'String' },
      { name: 'mobile', type: 'String' },
      { name: 'nric', type: 'String' },
      { name: 'duplication', type: 'Boolean' },
      { name: 'search_all', type: 'Boolean' },
    ],
    argsMap: [
      { name: 'query', value: 'query ' },
      { name: 'name', value: 'name ' },
      { name: 'email', value: 'email ' },
      { name: 'mobile', value: 'mobile ' },
      { name: 'nric', value: 'nric ' },
      { name: 'duplication', value: 'duplication ' },
      { name: 'search_all', value: 'search_all ' },
    ],
    fields: '{...SearchCustomersResponseFields}',
    fragments: [Fragments.searchCustomersResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST list customer GraphQL schema.
export const LIST = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listCustomers',
    args: [
      { name: 'filters', type: '[Filter]' },
      { name: 'sorts', type: '[Sort]' },
      { name: 'limit', type: 'Int' },
      { name: 'per_query', type: 'Int' },
      { name: 'pit_id', type: 'String' },
      { name: 'search_after', type: 'String' },
      { name: 'list_back', type: 'Boolean' },
      { name: 'page', type: 'Int' },
      { name: 'all', type: 'Boolean' },
      { name: 'no_opportunities', type: 'Boolean' },
    ],
    argsMap: [
      { name: 'filters', value: 'filters' },
      { name: 'sorts', value: 'sorts' },
      { name: 'limit', value: 'limit' },
      { name: 'per_query', value: 'per_query' },
      { name: 'pit_id', value: 'pit_id' },
      { name: 'search_after', value: 'search_after' },
      { name: 'list_back', value: 'list_back' },
      { name: 'page', value: 'page' },
      { name: 'all', value: 'all' },
      { name: 'no_opportunities', value: 'no_opportunities' },
    ],
    fields: '{...ListCustomersResponseFields}',
    fragments: [Fragments.listCustomersResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// DOWNLOAD download customers GraphQL schema.
export const DOWNLOAD = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'downloadCustomers',
    args: [
      { name: 'ids', type: '[String]' },
      { name: 'filters', type: '[Filter!]' },
    ],
    argsMap: [
      { name: 'ids', value: 'ids' },
      { name: 'filters', value: 'filters' },
    ],
    fields: '{...DownloadCustomersResponseFields}',
    fragments: [Fragments.downloadCustomersResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// READ read note GraphQL schema.
export const READ_NOTE = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getNote',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...NoteFields}',
    fragments: [NoteFragments.customerNote],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST list notes GraphQL schema.
export const LIST_NOTE = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listNotes',
    args: [
      { name: 'customer_id', type: 'String', required: true },
      { name: 'limit', type: 'Int' },
      { name: 'offset', type: 'Int' },
    ],
    argsMap: [
      { name: 'customer_id', value: 'customer_id' },
      { name: 'limit', value: 'limit' },
      { name: 'offset', value: 'offset' },
    ],
    fields: '{...ListNoteResponseFields}',
    fragments: [NoteFragments.listCustomerNoteResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST_ACTIVITIES list customer activities GraphQL schema.
export const LIST_ACTIVITIES = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listActivities',
    args: [
      { name: 'customer_id', type: 'String', required: true },
      { name: 'limit', type: 'Int' },
      { name: 'offset', type: 'Int' },
    ],
    argsMap: [
      { name: 'customer_id', value: 'customer_id' },
      { name: 'limit', value: 'limit' },
      { name: 'offset', value: 'offset' },
    ],
    fields: '{...ListActivitiesResponseFields}',
    fragments: [Fragments.listActivitiesResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST list of customer csv uploads GraphQL schema.
export const LIST_CUSTOMER_UPLOADS = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listCustomerUploads',
    args: [
      { name: 'prefix', type: 'String' },
      { name: 'limit', type: 'Int' },
      { name: 'offset', type: 'Int' },
    ],
    argsMap: [
      { name: 'prefix', value: 'prefix' },
      { name: 'limit', value: 'limit' },
      { name: 'offset', value: 'offset' },
    ],
    fields: '{...ListCustomerUploadsResponseFields}',
    fragments: [Fragments.listCustomerUploadsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};


// LIST list of customer csv exports GraphQL schema.
export const LIST_CUSTOMER_EXPORTS = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listCustomerExports',
    args: [
      { name: 'prefix', type: 'String' },
      { name: 'limit', type: 'Int' },
      { name: 'offset', type: 'Int' },
    ],
    argsMap: [
      { name: 'prefix', value: 'prefix' },
      { name: 'limit', value: 'limit' },
      { name: 'offset', value: 'offset' },
    ],
    fields: '{...ListCustomerExportsResponseFields}',
    fragments: [Fragments.listCustomerExportsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
