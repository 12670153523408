import _truncate from 'lodash/truncate';
import { oddOrEven } from '.';
import { countries } from '../data';
import { getUnitInfo } from '../data/mhub_api';

// Make first letter uppercase in the string
export function toTitleCase(str) {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return '';
}

// Make each first letter of the word into uppercase and lowercase the rest in the string
export function toCapitalize(str) {
  if (str) {
    const words = str.split(' ');
    return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  }
  return '';
}

export function formatValue(str, delimiter = ' ', toKey = false) {
  if (str && typeof str === 'string') {
    if (!toKey) {
      const newString = str.replace(/_/g, delimiter).trim();
      return toTitleCase(newString);
    }
    const newString = str.toLowerCase();
    return newString.replace(/ /g, delimiter);
  }
  return '';
}

export function TruncateString(string, length = 100, ending = '...') {
  if (string) {
    if (string.length > length) {
      return string.substring(0, length - ending.length) + ending;
    }
    return string;
  }
}

export function isJSON(str) {
  if (!str) {
    return false;
  }
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function getFieldLabel(name, customLabel) {
  const list = {
    assigned_to: 'Assignee',
    aging_days: 'Lead aging (days)',
    aging_days_created_at: 'Lead aging (Days since added)',
    aging_days_imported_at: 'Lead aging (Days since imported)',
    bumi: 'Bumiputera status',
    campaign_id: 'Campaign name',
    campaign_ids: 'Campaign name',
    company_branch_id: 'Business unit name',
    cancellation_reason_id: 'Cancellation reason',
    country_code: 'Country',
    channel_id: 'Campaign channel name',
    project_ids: 'Project name',
    project_id: 'Project name',
    nationality_country_code: 'Nationality',
    msisdn: 'Phone number',
    scheduled_at: 'Scheduled on',
    srb_created_at: 'SRB registration date',
    buyer_access_project_ids: 'SRB project access',
    total_budget: 'Budget',
    total_spent: 'Actual spent',
    created_at: 'Added on',
    created_by: 'Added by',
    updated_at: 'Last updated on',
    updated_by: 'Last updated by',
    imported_at: 'Imported on',
    imported_by: 'Imported by',
    import_id: 'Import Id',
    qualified_at: 'Qualified on',
    qualified_by: 'Qualified by',
    deleted_at: 'Deleted on',
    deleted_by: 'Deleted by',
    merged_at: 'Merged on',
    merged_by: 'Merged by',
    inbound_call_at: 'Inbound call on',
    call_at: 'Call on',
    call_by: 'Caller',
    call_from: 'Caller',
    meeting_url: 'Online meeting URL',
    form_id: 'Form name',
    unit_id: 'Unit number',
    primary_customer_id: 'Primary customer',
    customer_ids: 'Related customers',
    booking_id: 'Booking number',
    utm_campaign: 'UTM campaign',
    utm_content: 'UTM content',
    utm_medium: 'UTM medium',
    utm_source: 'UTM source',
    utm_term: 'UTM term',
    submitted_at: 'Submitted on',
  };
  if (customLabel) {
    return customLabel;
  }
  return list[name] || formatValue(name) || '';
}

export function getAppointmentFieldLabel(name) {
  const list = {
    name: 'Appointment name',
    type: 'Appointment type',
    status: 'Appointment status',
    note_message: 'Note',
    meeting_url: 'Online meeting URL',
    date_time: 'Appointment date',
    customer_ids: 'Attendees',
    starting_at: 'Appointment date\'s From',
    ending_at: 'Appointment date\'s To',
  };
  return list[name] || formatValue(name) || '';
}

export function getLabelFromList(name, options) {
  if (name && options.length > 0) {
    const index = options.findIndex((option) => (
      option.value === name
    ));
    if (index > -1 && options[index].label) {
      return options[index].label;
    }
  }
  return name || '';
}

export function getCountryLabel(value) {
  if (value) {
    const country = countries.find((c) => c.value === value.toUpperCase());
    if (country && country.label) {
      return country.label;
    }
    return value;
  }
  return '';
}

export function getProjectName(item, projects) {
  if (projects[item.project_id] && projects[item.project_id].name) {
    return projects[item.project_id].name;
  }
  return (
    (item.project_id && item.project_id.toString())
    || ''
  );
}

export function getCampaignName(item, campaigns, showDeleted) {
  if (campaigns[item.campaign_id] && campaigns[item.campaign_id].name) {
    return campaigns[item.campaign_id].name;
  }
  if (showDeleted) {
    return '[Deleted campaign]';
  }
  return (
    (item.campaign_name && item.campaign_name.toString())
    || (item.campaign_id && item.campaign_id.toString())
    || ''
  );
}

// Project + Unit
export function getOpportunityName(item, projects, truncate = false, truncateLength = 25) {
  let name = '';
  let projectName = getProjectName(item, projects) || '';
  let unitNumber = item.unit_number || '';
  if (truncate) {
    projectName = _truncate(projectName, { length: truncateLength });
    unitNumber = _truncate(unitNumber, { length: truncateLength });
  }
  if (item.unit_number) {
    name = `${projectName || 'No project'}${`, ${unitNumber}`}`;
  } else {
    name = projectName;
  }
  return name;
}

// Include custom status such as refresh_required, taken, no_access, unit_required
export async function getProjectUnitStatus(opportunity, showroomApiURL) {
  let status = '';
  if (opportunity && opportunity.project_id) {
    if (opportunity.unit_id) {
      let res;
      // Got error means no access
      try {
        res = await getUnitInfo(
          showroomApiURL,
          opportunity.project_id,
          opportunity.unit_id,
          true,
        );
      } catch (e) {
        status = 'refresh_required';
      }

      if (!res) {
        status = 'refresh_required';
      } else if (res && !res.ok) {
        // Access forbidden and Not found
        if ([403, 404].includes(res.status)) {
          status = 'no_access';
        } else {
          status = 'refresh_required';
        }
      } else {
        const unitInfo = await res.json();
        if (unitInfo && unitInfo.statusID) {
          const unitStatus = unitInfo.statusID;
          if (unitStatus === 'delist') {
            status = unitStatus;
          } else if (
            opportunity.stage !== 'booked'
            && opportunity.unit_id
            && !opportunity.booking_id
            && unitStatus !== 'new'
          ) {
            // Show taken as long this opp dont have booking id and unit status is not new
            status = 'taken';
          } else {
            status = unitStatus;
          }
        }
      }
    } else {
      status = 'unit_required';
    }
  }
  return status;
}

export function getProjectUnitStatusInfo(status) {
  let label = '';
  let description = '';
  switch (status) {
    case 'taken':
      label = 'Already taken';
      break;
    case 'delist':
      label = 'Unit delisted';
      break;
    case 'no_access':
      label = 'No access';
      description = 'This could mean that your access to this project has been removed, project got deleted or the unit got deleted. The project/unit info you see now will be outdated too.';
      break;
    case 'refresh_required':
      label = 'Kindly refresh';
      description = 'This could mean that there\'s error when the system is retrieving project/unit info. The project/unit info you see now will be outdated too.';
      break;
    default:
      break;
  }
  return { label, description };
}

export function getNumberWithCommas(number) {
  if (number) {
    if (typeof number === 'number') {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return 0;
}

export function getRounderToXNumberWithCommas(num, precision) {
  let total = +(`${Math.round(`${num}e${precision}`)}e-${precision}`);
  // Need to be a string to keep the trailing zeros
  total = total.toFixed(precision);
  return getNumberWithCommas(total);
}

// Only supports MY NRIC until 99 years old
export function getInfoFromIC(ic) {
  let gender = '';
  let dateOfBirth = '';
  let birthYear = '';
  let birthMonth = '';
  let birthDay = '';
  if (ic) {
    const myICRegex = new RegExp(/^([0-9][0-9])((0[1-9])|(1[0-2]))((0[1-9]|[1-2][0-9]|3[0-1]))([0-9][0-9])([0-9][0-9][0-9][0-9])$/, 'i');
    // Remove hyphen and spaces
    const formattedIC = ic.replace(/-|\s/g, '');
    // Check if ic format is valid
    if (myICRegex.test(formattedIC)) {
      // ------ Get date of birth ------
      const year = formattedIC.substring(0, 2);
      const month = formattedIC.substring(2, 4);
      const day = formattedIC.substring(4, 6);
      const currentYear = (new Date()).getFullYear();
      const currentYearFirstDigit = parseInt(currentYear.toString().substring(0, 1), 10);
      const currentCentury = currentYearFirstDigit * 1000;
      const cutoff = currentYear - currentCentury;
      const currentCenturyICDigits = currentYearFirstDigit * 10;
      const centuryBeforeCurrentICDigits = currentCenturyICDigits - 1;
      const fullBirthYear = `${year > cutoff ? centuryBeforeCurrentICDigits.toString() : currentCenturyICDigits.toString()}${year}`;

      dateOfBirth = `${fullBirthYear}-${month}-${day}`;
      birthYear = fullBirthYear;
      birthMonth = month;
      birthDay = day;

      // ------- Get gender -------
      const genderDigits = formattedIC.substring(formattedIC.length - 4);
      switch (oddOrEven(genderDigits)) {
        case 'odd':
          gender = 'male';
          break;
        case 'even':
          gender = 'female';
          break;
        default:
          break;
      }
    }
  }

  return {
    year: birthYear,
    month: birthMonth,
    day: birthDay,
    dateOfBirth,
    gender,
  };
}

export function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function stringifyNestedObjects(payload) {
  const clonedValues = JSON.parse(JSON.stringify(payload));
  Object.keys(payload).forEach((key) => {
    if (clonedValues[key]
    && typeof clonedValues[key] === 'object'
    && !Array.isArray(clonedValues[key])) {
      clonedValues[key] = JSON.stringify(clonedValues[key]);
    }
  });
  return clonedValues;
}
