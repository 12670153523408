import React, {
  Fragment,
  useState,
  useEffect,
} from 'react';
import { useLazyQuery } from 'react-apollo';
import PropTypes from 'prop-types';
import moment from 'moment';
import _, { isEmpty } from 'lodash';
import {
  createValidity,
  required,
  validate,
  email,
  identity,
  stringLength,
  matchRegex,
} from '@mhub/web-validations';
import {
  ClickAwayListener,
  Icon,
  TextField,
  Typography,
  useMediaQuery,
  Button,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import {
  MobileInput,
  Tooltip,
} from '..';
import {
  Countries,
  Searchable,
  SearchableAssignee,
  SearchableProject,
} from '../Dropdown';
import {
  CalendarIcon,
  EditIcon,
  AlertCircleIcon,
  CheckCircleFilled,
} from '../../assets/icons';
import {
  formatValue,
  getCampaignName,
  getCountryLabel,
  getLabelFromList,
  isEmptyValue,
  isJSON,
  previewDate,
  previewDateTime,
  toCapitalize,
} from '../../utils';
import { states } from '../../data/places';
import SimpleModal from '../SimpleModal';
import TextFieldWithRegex from '../TextFieldWithRegex';
import { CUSTOMER } from '../../graphql';
import { clientCustomer } from '../..';
import { systemSource, utmFields } from '../../data/settings';
import useStyles from './styles';

export default function QuickEditField(props) {
  const {
    type,
    item,
    users,
    projects,
    campaigns,
    settings,
    category,
    name,
    emergencyContactIndex,
    disabled,
    onClickAway,
  } = props;

  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const [fieldsWithOptions] = useState(['dropdown', 'multiselections']);
  const [selected, setSelected] = useState('');
  const [valueSource, setValueSource] = useState({});
  const [value, setValue] = useState({});
  const [uneditableFields, setUneditableFields] = useState([]);
  const [error, setError] = useState('');
  // To give time for validation after user changed value
  const [done, setDone] = useState(true);

  const [activeStates, setActiveStates] = useState({
    mobileDisabledWarning: false,
    tinDisabledWanring: false,
  });

  const toggleState = (stateName, state) => () => {
    setActiveStates((s) => ({ ...s, [stateName]: state || !s[stateName] }));
  };

  const [searchCustomersMobileQuery, {
    data: searchCustomersMobileData = { searchCustomers: { body: JSON.stringify([]), count: 0 } },
    error: searchCustomersMobileError,
  }] = useLazyQuery(CUSTOMER.SEARCH(), {
    client: clientCustomer,
    fetchPolicy: 'no-cache',
  });

  const [searchCustomersEmailQuery, {
    data: searchCustomersEmailData = { searchCustomers: { body: JSON.stringify([]), count: 0 } },
    error: searchCustomersEmailError,
  }] = useLazyQuery(CUSTOMER.SEARCH(), {
    client: clientCustomer,
    fetchPolicy: 'no-cache',
  });

  const [searchCustomersNricQuery, {
    data: searchCustomersNricData = { searchCustomers: { body: JSON.stringify([]), count: 0 } },
    error: searchCustomersNricError,
  }] = useLazyQuery(CUSTOMER.SEARCH(), {
    client: clientCustomer,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    let initialUneditableFields = [
      'imported_at',
      'imported_by',
      'created_at',
      'created_by',
      'updated_at',
      'updated_by',
      'srb_created_at',
      'qualified_at',
      'aging_days',
      ...utmFields,
    ];
    const disableSource = name === 'source' && (
      systemSource.map((source) => source.value).includes(value[name])
      || !item.created_by
      || !!item.inbound_call_at
    );
    if (disableSource) {
      initialUneditableFields.push('source');
    } else {
      initialUneditableFields = initialUneditableFields.filter((f) => f !== 'source');
    }

    const disableChannel = name === 'channel_id' && (
      disableSource
      || item.form_id
      || item.form_name
      || !item.campaign_id
      || !!item.inbound_call_at
    );
    if (disableChannel) {
      initialUneditableFields.push('channel_id');
    } else {
      initialUneditableFields = initialUneditableFields.filter((f) => f !== 'channel_id');
    }

    const disableCampaign = (
      name === 'campaign_id' && (
        !item.created_by
        || !!item.inbound_call_at
      )
    );
    if (disableCampaign) {
      initialUneditableFields.push('campaign_id');
    } else {
      initialUneditableFields = initialUneditableFields.filter((f) => f !== 'campaign_id');
    }

    if (type === 'customer' && !!item.srb_id && category === 'basic') {
      initialUneditableFields.push('mobile');
    }

    const disableTIN = (
      (name === 'tin' || name === 'nric')
      && item.tin_validation_status === 'success'
      && item.nric !== ''
      && item.tin !== ''
    );

    if (disableTIN) {
      initialUneditableFields.push('nric');
      initialUneditableFields.push('tin');
    }

    setUneditableFields(initialUneditableFields);
  }, [
    name,
    value,
    item,
    type,
    category,
  ]);

  useEffect(() => {
    let source = {};
    if (category === 'emergency_contact') {
      if (item
        && item.emergency_contacts
        && item.emergency_contacts.length > 0
        && item.emergency_contacts[emergencyContactIndex]) {
        setValue({ [name]: item.emergency_contacts[emergencyContactIndex][name] || '' });
        source = item.emergency_contacts[emergencyContactIndex];
      }
    } else {
      setValue({ [name]: item[name] });
      source = item;
    }
    setError('');
    setSelected('');
    setValueSource(source);
  }, [
    category,
    item,
    name,
    emergencyContactIndex,
  ]);

  useEffect(() => {
    // Only customer quick edit field has duplicate checking validations
    if (type === 'customer'
      && category === 'basic'
      && selected === name
      && value[name] && ['mobile', 'email', 'nric'].includes(name)) {
      let queryTypes = [];

      switch (name) {
        case 'mobile':
          queryTypes = searchCustomersMobileQuery;
          break;
        case 'email':
          queryTypes = searchCustomersEmailQuery;
          break;
        case 'nric':
          queryTypes = searchCustomersNricQuery;
          break;
        default:
          break;
      }

      queryTypes({
        variables: {
          [name]: value[name],
          duplication: true,
        },
      });
    }
  }, [
    category,
    type,
    name,
    value,
    selected,
    searchCustomersMobileQuery,
    searchCustomersEmailQuery,
    searchCustomersNricQuery,
  ]);

  const debounceCallBack = _.debounce((e, callBack) => {
    callBack();
  }, 200);

  const formatValidationValue = (inputType, v) => {
    if ((!v || isEmpty(v)) && inputType === 'multiselections') {
      return [];
    }
    return v || '';
  };

  const getDateValidation = (v) => {
    const validity = createValidity(name);
    if (v && !moment(v).isValid()) {
      validity.isValid = false;
      validity.message = 'Invalid time';
    }
    return validity;
  };

  const getArrayValidation = (v) => {
    const validity = createValidity(name);
    if (v && !Array.isArray(v)) {
      validity.isValid = false;
      validity.message = 'Value need to be in array format';
    } else if (!v || isEmpty(v)) {
      validity.isValid = false;
      validity.message = 'Atleast one selection is required';
    }
    return validity;
  };

  const getValidations = ({ isRequired = false, inputType }) => {
    const validations = [];
    if (isRequired) {
      // Do different required checking of array values
      if (inputType === 'multiselections' || (value[name] && Array.isArray(value[name]))) {
        validations.push((v) => getArrayValidation(v));
      } else {
        validations.push(required);
      }
    }
    switch (name) {
      case 'email':
        return validations.concat(email);
      case 'mobile':
        return validations.concat(
          (v, o) => required((value.mobile_country_code || item.mobile_country_code), {
            ...o,
            label: 'Mobile prefix',
            message: 'Mobile prefix is required',
          }),
          (v, o) => matchRegex(v, {
            ...o,
            validRegex: '^[0-9]*$',
            message: 'Mobile should only contain numbers without spaces',
          }),
          (v, o) => stringLength(v, {
            ...o,
            min: 7,
            max: 15,
            message: 'Mobile should be within 7 to 15 digits',
          }),
        );
      case 'nric':
        // only validate malaysia nric
        return validations.concat((v, o) => identity(v, {
          ...o,
          identityType: 'ic_number',
          countryCode: 'MY',
        }));
      case 'date_of_birth':
        return validations.concat((v) => getDateValidation(v));
      default:
        return validations;
    }
  };

  const getFieldValidations = (v, field) => {
    const { label, required: requiredField, input_type: inputType } = field;
    const validations = getValidations({ isRequired: requiredField, inputType });
    if (validations.length > 0) {
      validate({
        name,
        label,
        value: formatValidationValue(inputType, v),
        validations,
      }).then((result) => {
        if (!result.message) {
          setError('');
        } else {
          setError(result.message);
        }
      });
    }
  };

  const getSearchQueryResults = (field) => {
    if (value[name]) {
      switch (type) {
        case 'customer':
          switch (field) {
            case 'mobile':
              if (!searchCustomersMobileError && searchCustomersMobileData && _.get(searchCustomersMobileData, 'searchCustomers.body')) {
                return JSON.parse(searchCustomersMobileData.searchCustomers.body);
              }
              break;
            case 'email':
              if (!searchCustomersEmailError && searchCustomersEmailData && _.get(searchCustomersEmailData, 'searchCustomers.body')) {
                return JSON.parse(searchCustomersEmailData.searchCustomers.body);
              }
              break;
            case 'nric':
              if (!searchCustomersNricError && searchCustomersNricData && _.get(searchCustomersNricData, 'searchCustomers.body')) {
                return JSON.parse(searchCustomersNricData.searchCustomers.body);
              }
              break;
            default:
              return [];
          }
          break;
        default:
          return [];
      }
    }
    return [];
  };

  const getDuplicateResults = () => {
    let results = [];
    if (type) {
      const duplicateByMobile = getSearchQueryResults('mobile');
      const duplicateByEmail = getSearchQueryResults('email');
      const duplicateByNric = getSearchQueryResults('nric');
      const duplicates = duplicateByMobile.concat(duplicateByEmail, duplicateByNric);
      results = duplicates.filter((duplicate, index) => (
        duplicates.findIndex((d) => d.id === duplicate.id) === index) > -1);
      if (type === 'customer' && item.id) {
        results = results.filter((result) => result.contact && result.contact.id !== item.id);
      }
    }
    return results;
  };

  const handleClickAway = (e) => {
    if (!error && done && selected === name) {
      if (type === 'customer'
        && category === 'basic'
        && ((['mobile', 'email', 'nric'].includes(name) && item[name] !== value[name]))) {
        const haveDuplicateResults = value && getDuplicateResults().length > 0;
        if (haveDuplicateResults) {
          setError(`Duplicated record found. ${name} already exists.`);
          return;
        }
      }

      setSelected('');
      if (onClickAway) {
        onClickAway(e, value);
      }
    }
  };

  const handleChangeInput = (e, field) => {
    e.persist();
    setDone(false);
    debounceCallBack(e, async () => {
      await setValue({ [name]: e.target.value });
      await getFieldValidations(e.target.value, field);
      setDone(true);
    });
  };

  const handleOnChangeMobile = (e, v, field) => {
    setDone(false);
    debounceCallBack(e, async () => {
      if (!(v.number && v.country_code)) {
        setValue({});
      } else {
        await setValue({
          mobile: v.number,
          mobile_country_code: v.country_code,
          msisdn: v.msisdn,
        });
      }
      await getFieldValidations(v.number, field);
      setDone(true);
    });
  };

  const handleOnChangeAssignee = (e, v) => {
    setValue({ [name]: v || '' });
  };

  const handleChangeMultipleDropdown = (e, v, field) => {
    getFieldValidations(v, field);
    setValue({ [name]: v });
  };

  const handleChangeCountry = (e, info, field) => {
    setDone(false);
    let v = { [name]: info.value };

    if (name === 'country_code' && item[name] !== 'MY' && info.value === 'MY') {
      v = ({
        ...v,
        state: '',
      });
    }

    debounceCallBack(e, async () => {
      await setValue(v);
      await getFieldValidations(info.value, field);
      setDone(true);
    });
  };

  const handleChangeDropdown = (e, field, { selected: s, object }) => {
    setDone(false);
    let v = { [name]: s };

    // Pass in campaign_id and campaign_name
    if (name === 'campaign_id') {
      v = {
        [name]: s,
        campaign_name: object.label,
        channel_id: '',
        channel_name: '',
      };
    } else if (name === 'channel_id') {
      v = {
        [name]: s,
        channel_name: object.label,
      };
    }

    debounceCallBack(e, async () => {
      await setValue(v);
      await getFieldValidations(s, field);
      setDone(true);
    });
  };

  const handleChangeDate = (v, field) => {
    setDone(false);
    debounceCallBack(_, async () => {
      const date = v ? moment(v).format('YYYY-MM-DD') : null;
      if (date) {
        await setValue({ [name]: date });
        await getFieldValidations(date, field);
      } else {
        await setValue({ [name]: null });
        await getFieldValidations('', field);
      }
      setDone(true);
    });
  };

  const getUserName = (id) => {
    const u = users[id];
    if (u) {
      if (u.name) {
        return u.name;
      }
      if (u.preferred_name) {
        return u.preferred_name;
      }
    }
    return id;
  };

  const formatFieldValue = (field, v) => {
    // Get dropdown fields options label from settings
    if (field.input_type && fieldsWithOptions.includes(field.input_type)) {
      // If is array, render as array of string
      if (Array.isArray(v)) {
        let formatted = '';
        if (field.input_type === 'multiselections') {
          formatted = v.map((val) => getLabelFromList(val, field.options || [])).toString();
        } else {
          // Render raw value for dropdown type if value is array
          formatted = v.toString();
        }
        return formatted;
      }
      return getLabelFromList(v, field.options || []);
    }

    // Render string comma in viewing mode when value is array
    if (v && Array.isArray(v) && v.length > 0) {
      return v.toString();
    }

    // To support list value format coming from submission
    // Convert into string with seperated comma
    if (isJSON(v)) {
      let formatted = JSON.parse(v);
      if (Array.isArray(formatted) && formatted.length) {
        formatted = formatted.map((f) => (f.name));
        return formatted.toString();
      }
    }
    return !isEmptyValue(v) ? v : '';
  };

  const getProjectName = () => {
    if (Array.isArray(item.project_ids) && item.project_ids.length > 0) {
      const projectNames = item.project_ids.map((id) => (
        (projects[id] && projects[id].name) || id
      ));
      return projectNames.join(' , ');
    }
    if (projects[item.project_ids] && projects[item.project_ids].name) {
      return projects[item.project_ids].name;
    }
    return item.project_ids;
  };

  const getChannelName = (options, id, values) => {
    if (options && options.length > 0) {
      const s = options.find((opt) => (
        opt.value === id
      ));
      if (s && s.label) {
        return s.label;
      }
      if (values && values.channel_name) {
        return `${values.channel_name} (Deleted)`;
      }
    }
    return id;
  };

  const renderFieldValue = (values) => {
    const field = settings[category].attributes[name];
    if (field && field.loading) {
      return 'Loading...';
    }

    switch (name) {
      case 'name':
        return toCapitalize(values[name]);
      case 'country_code':
      case 'nationality_country_code':
        return getCountryLabel(values[name]);
      case 'mobile':
        return values.msisdn || values.mobile || values.phone;
      case 'imported_at':
      case 'created_at':
      case 'qualified_at':
      case 'updated_at':
        return previewDateTime(values[name]);
      case 'srb_created_at':
        return previewDate(values[name]);
      case 'assigned_to':
        return values[name] ? getUserName(values[name]) : 'Unassigned';
      case 'imported_by':
      case 'created_by':
      case 'updated_by':
        return getUserName(values[name]);
      case 'submission_source':
        return values.form_name;
      case 'campaign_id':
        return getCampaignName(item, campaigns);
      case 'channel_id':
        return getChannelName(field.options || [], values[name], values);
      case 'project_ids':
        return getProjectName();
      default:
        return formatFieldValue(field, values[name]);
    }
  };

  const renderInput = (field) => {
    let defaultValue = formatFieldValue(field, valueSource[name]);
    // Render form name in submission source else hide it
    if (name === 'submission_source') {
      if (!valueSource.form_name) {
        return null;
      }
      defaultValue = valueSource.form_name;
    }

    const fieldProps = {
      key: name,
      fullWidth: true,
      variant: 'outlined',
      name,
      required: field.required,
      error: !!error,
      helperText: error,
      InputProps: {
        disabled: name === 'submission_source',
        autoComplete: 'new-password',
        type: field.input_type,
      },
      onChange: (e) => handleChangeInput(e, field),
      onKeyPress: (e) => {
        if (e.key === 'Enter') {
          handleClickAway();
        }
      },
    };

    // NRIC text field don't allow number
    if (name === 'nric') {
      return (
        <TextFieldWithRegex
          {...fieldProps}
          type="number"
          value={defaultValue}
        />
      );
    }

    return (
      <TextField
        {...fieldProps}
        defaultValue={defaultValue}
        InputLabelProps={{
          shrink: false,
          variant: 'standard',
          disableAnimation: true,
        }}
      />
    );
  };

  const renderMobileInput = (field) => {
    const number = valueSource.mobile || valueSource.msisdn || '';
    const countryCode = valueSource.mobile_country_code || '';

    return (
      <MobileInput
        hideFlag
        disabledPortal={false}
        name={name}
        data={{
          number,
          country_code: countryCode,
        }}
        error={error}
        required={field.required}
        onChange={(e, v) => handleOnChangeMobile(e, v, field)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleClickAway();
          }
        }}
      />
    );
  };

  const renderDropdown = (field) => {
    let newValue = value[name];
    let newOptions = field.options || [];

    // Convert list object into a string seperated by comma
    // To support checklist from Oasis form submission
    if (isJSON(value[name])) {
      const formatted = isJSON(valueSource[name]);
      if (Array.isArray(formatted) && formatted.length) {
        const formattedList = formatted.map((v) => v.name).toString;
        newValue = formatValue(formattedList, '_', true);
        const valueExists = newOptions
          ? newOptions.filter((option) => newValue === option.value).length > 0
          : [];
        // Append the value into the options if dont have
        if (!valueExists) {
          newOptions.push({ label: formattedList, value: formatValue(formattedList, '_', true) });
        }
      }
    } else {
      let haveValue = true;
      let newDefaultValue = valueSource[name];

      // When switch from multiselection to dropdown type
      // Need to convert array to string commas
      if (field.input_type !== 'multiselections' && !field.multiple) {
        if (valueSource[name] && Array.isArray(valueSource[name])) {
          newDefaultValue = valueSource[name].toString();
        }
        if (newValue && Array.isArray(newValue)) {
          newValue = newValue.toString();
        }
      } else {
        if (!Array.isArray(valueSource[name])) {
          newDefaultValue = valueSource[name] ? [valueSource[name]] : [];
        }
        if (!Array.isArray(newValue)) {
          newValue = newValue ? [newValue] : [];
        }
      }

      // Support prev value that is no longer inside options
      if (newDefaultValue && Array.isArray(newDefaultValue)) {
        newDefaultValue.forEach((v) => {
          haveValue = newOptions
            .filter((option) => v === option.value).length > 0;
          if (!haveValue) {
            newOptions = [{ label: v, value: v }].concat(newOptions);
          }
        });
      } else {
        haveValue = newOptions
          .filter((option) => newDefaultValue === option.value).length > 0;
        if (newDefaultValue && !haveValue) {
          switch (name) {
            case 'campaign_id':
              // Append with campaign name if exists in cam listing from context
              newOptions = [{
                label: (
                  (campaigns[newDefaultValue] && campaigns[newDefaultValue].name)
                  || valueSource.campaign_name
                  || newDefaultValue
                ),
                value: newDefaultValue,
              }].concat(newOptions);
              break;
            case 'channel_id':
              newOptions = [{
                label: (
                  `${valueSource.channel_name} (Deleted)`
                  || newDefaultValue
                ),
                value: newDefaultValue,
              }].concat(field.options);
              break;
            default:
              newOptions = [{ label: newDefaultValue, value: newDefaultValue }]
                .concat(newOptions);
              break;
          }
        }

        // If only have campaign_name
        if (name === 'campaign_id' && !newDefaultValue && valueSource.campaign_name) {
          newOptions = [
            { label: valueSource.campaign_name, value: valueSource.campaign_name, disabled: true },
          ].concat(newOptions);
          newValue = valueSource.campaign_name;
        }
      }
    }

    if (field.multiple || field.input_type === 'multiselections') {
      return (
        <Fragment key={name}>
          <Searchable
            multiple
            filterSelectedOptions
            error={error}
            required={field.required}
            options={newOptions}
            value={newValue}
            onChange={(e, v) => handleChangeMultipleDropdown(e, v, field)}
          />
        </Fragment>
      );
    }

    return (
      <Fragment key={name}>
        <Searchable
          error={error}
          loading={field.loading}
          placeholder={field.loading ? 'Loading...' : 'Search'}
          disabled={field.loading || field.disabled}
          required={field.required}
          options={newOptions}
          value={newValue}
          onChange={(e, s, object) => handleChangeDropdown(e, field, { selected: s, object })}
        />
      </Fragment>
    );
  };

  const renderDatePicker = (field) => (
    <div key={name}>
      <KeyboardDatePicker
        clearable
        disableFuture
        fullWidth
        required={field.required}
        inputVariant="outlined"
        openTo="year"
        views={['year', 'month', 'date']}
        format="YYYY/MM/DD"
        placeholder="YYYY/MM/DD"
        disabled={field.disabled}
        {...(error ? { error: true } : {})}
        {...(error ? { helperText: error } : {})}
        value={value[name] || null}
        onChange={(date) => handleChangeDate(date, field)}
        InputLabelProps={{
          shrink: false,
          variant: 'standard',
          disableAnimation: true,
        }}
        keyboardIcon={(
          <Icon className={classes.calendarIcon}>
            <img alt="date" src={CalendarIcon} />
          </Icon>
        )}
        KeyboardButtonProps={{
          classes: {
            root: classes.datePickerRoot,
          },
        }}
      />
    </div>
  );

  const renderCountryDropdown = (field) => (
    <Countries
      addNotApplicable
      name={field.name}
      required={field.required}
      error={error}
      value={value[name]}
      onChange={(e, v) => handleChangeCountry(e, v, field)}
    />
  );

  const handleWarningModal = (stateName) => (e) => {
    if (e) {
      e.stopPropagation(); // Stop event propagation if the event is provided
    }
    toggleState(stateName)();
  };

  const renderIcon = () => {
    let helperText = '';
    let isAlert = true;
    if (category === 'basic') {
      if (name === 'mobile' && (item.mobile || item.msisdn) && !item.mobile_country_code) {
        helperText = 'Missing country code for mobile number (eg. "MY" or "60")';
      } else if (type === 'customer' && name === 'email' && !item.email_is_reachable) {
        helperText = 'This is an invalid email. Email blast will not work on this email';
      } else if (item.tin_validation_status === 'success') {
        if (name === 'tin' && item.tin) {
          helperText = 'TIN is verified on Showroom';
          isAlert = false;
        } else if (name === 'nric' && item.nric && item.tin) {
          helperText = 'NRIC is verified on Showroom';
          isAlert = false;
        }
      }
    }

    if (helperText) {
      return (
        <Tooltip title={helperText} placement="bottom-start">
          <Icon>
            <img
              className={`${classes.statusIcon} ${isAlert ? classes.alert : classes.verified}`}
              alt=""
              src={isAlert ? AlertCircleIcon : CheckCircleFilled}
            />
          </Icon>
        </Tooltip>
      );
    }
    return null;
  };

  const renderField = () => {
    if (selected !== name) {
      return (
        <Typography
          className={`
            ${classes.value}
            ${name === 'aging_days' && valueSource[name] && valueSource[name] > 7 ? 'red' : ''}
          `}
        >
          { renderFieldValue(valueSource) }
        </Typography>
      );
    }

    if (settings[category]
      && settings[category].attributes
      && settings[category].attributes[name]
    ) {
      const field = settings[category].attributes[name];

      if (name === 'mobile') {
        return renderMobileInput(field);
      }

      if (['country_code', 'nationality_country_code'].includes(name)) {
        return renderCountryDropdown(field);
      }

      if (name === 'state') {
        if (item.country_code === 'MY') {
          return renderDropdown({
            ...field,
            options: states,
          });
        }
        return renderInput(field);
      }

      if (name === 'assigned_to') {
        return (
          <SearchableAssignee
            restrictOptions
            value={value[name]}
            onChange={handleOnChangeAssignee}
          />
        );
      }

      if (name === 'project_ids') {
        return (
          <SearchableProject
            multiple
            filterSelectedOptions
            required={field.required}
            value={value[name] || []}
            error={error}
            disabled={disabled}
            onChange={(e, v) => handleChangeMultipleDropdown(e, v, field)}
          />
        );
      }

      switch (field.input_type) {
        case 'text':
        case 'number':
          return renderInput(field);
        case 'dropdown':
        case 'multiselections':
          return renderDropdown(field);
        case 'date':
          return renderDatePicker(field);
        default:
          break;
      }
    }

    return null;
  };

  const isEditable = !(isMobile || uneditableFields.includes(name) || disabled);
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        className={`
        ${classes.fieldWrapper}
        ${!isEditable ? 'uneditable' : ''}
        ${selected === name ? 'active' : ''}
      `}
        onClick={(e) => {
          e.stopPropagation();
          if (isEditable) {
            setSelected(name);
          }
          if (!isEditable && !isMobile && name === 'mobile' && type === 'customer' && category === 'basic') {
            toggleState('mobileDisabledWarning')();
          }
          if (!isEditable && !isMobile && (name === 'tin' || name === 'nric') && type === 'customer' && category === 'basic') {
            toggleState('tinDisabledWanring')();
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            handleClickAway(); // Trigger the same behavior as click away
          } else if (e.key === ' ') {
            e.preventDefault();
            e.stopPropagation();
            if (isEditable) {
              setSelected(name);
            }
            if (!isEditable && !isMobile && name === 'mobile' && type === 'customer' && category === 'basic') {
              toggleState('mobileDisabledWarning')();
            }
            if (!isEditable && !isMobile && (name === 'tin' || name === 'nric') && type === 'customer' && category === 'basic') {
              toggleState('tinDisabledWanring')();
            }
          }
        }}
        role="button"
        tabIndex={0}
      >
        { renderField(category, name) }
        {
          selected !== name && (
            <div className={classes.buttonWrapper}>
              { renderIcon() }
              <Icon className="editIcon">
                <img
                  className="edit"
                  alt={name}
                  src={EditIcon}
                />
              </Icon>
            </div>
          )
        }
        <SimpleModal
          title="Profile linked to SRB"
          open={activeStates.mobileDisabledWarning}
          onCancel={handleWarningModal('mobileDisabledWarning')}
          content={(
            <>
              <Typography variant="body2">
                This customer has an active account on our MHub Showroom for Buyer (SRB) app.
                You are unable to edit the mobile number as it is linked to that account.
                Kindly contact support@mhub.my for further assistance
              </Typography>
            </>
          )}
          buttons={(
            <Button
              variant="contained"
              onClick={handleWarningModal('mobileDisabledWarning')}
            >
              Okay
            </Button>
          )}
        />
        <SimpleModal
          title="TIN/NRIC is already verified"
          open={activeStates.tinDisabledWanring}
          onCancel={handleWarningModal('tinDisabledWanring')}
          content={(
            <>
              <Typography variant="body2">
                This customer has a verified TIN on our MHub Showroom for Buyer (SRB) app.
                You are unable to edit the TIN and NRIC Number as it is already verified.
                Kindly contact support@mhub.my for further assistance
              </Typography>
            </>
          )}
          buttons={(
            <Button
              variant="contained"
              onClick={handleWarningModal('tinDisabledWanring')}
            >
              Okay
            </Button>
          )}
        />
      </div>
    </ClickAwayListener>

  );
}

QuickEditField.propTypes = {
  type: PropTypes.oneOf([
    'lead',
    'customer',
  ]),
  category: PropTypes.string,
  name: PropTypes.string,
  emergencyContactIndex: PropTypes.number,
  disabled: PropTypes.bool,
  item: PropTypes.instanceOf(Object),
  users: PropTypes.instanceOf(Object),
  projects: PropTypes.instanceOf(Object),
  campaigns: PropTypes.instanceOf(Object),
  settings: PropTypes.instanceOf(Object),
  onClickAway: PropTypes.func,
};

QuickEditField.defaultProps = {
  type: 'lead',
  category: '',
  name: '',
  emergencyContactIndex: 0,
  disabled: false,
  item: {},
  users: {},
  projects: {},
  campaigns: {},
  settings: {},
  onClickAway: () => {},
};
