import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isArray } from 'lodash';
import {
  Checkbox,
  Box,
  Chip,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isEmptyValue } from '../../../utils';
import { allOption } from '../../../data/settings';
import useStyles from './styles';

// Options can be in array of string or array of object { label, value, ... }

// Known issue (That only can be fixed via material upgrade):
// Search to change options + multiple props + undefined value will cause input value to reset
// on each keystroke. Work around now is to make sure it value is [] when iniiate
export default function Searchable(props) {
  const {
    anchorRef, error,
    label, placeholder,
    textBetweenTags,
    type,
    addSelectAllOption,
    options, value,
    optionLabelName,
    required, onChange,
    hideErrorText,
    customTags, enableLoadMore,
    ...autocompleteProps
  } = props;

  const classes = useStyles(props);
  const isSelectAllCheckbox = (
    type === 'checkbox'
    && autocompleteProps.multiple
    && addSelectAllOption
    && !isEmptyValue(options)
    && options.length < 100 // API have 100 limit per field for filters
  );

  const selectAllOption = isSelectAllCheckbox
    && options.findIndex((opt) => opt.value === allOption.value) < 0
    ? [allOption]
    : [];

  const loadMoreOption = enableLoadMore && options && options.length > 0
    ? [{ label: 'Load more', isLoadMore: true }]
    : [];

  const newOptions = [...selectAllOption, ...options, ...loadMoreOption];

  const handleOnChange = (e, values, reason) => {
    // To fix backspace will still remove tag that rendered outside
    if (reason === 'remove-option'
    && autocompleteProps.multiple
    && customTags) {
      return;
    }

    if (autocompleteProps.multiple && isArray(values)) {
      let newValues = values.filter((v) => v && (v.value || ['string', 'number'].includes(typeof v))).map((v) => v.value || v);
      let arrObjects = values;

      if (isSelectAllCheckbox
        && newValues.includes(allOption.value)
        && newValues.length === newOptions.length) {
        // Deselect all if select checked "Select all"
        newValues = [];
        arrObjects = [];
      }

      // For multiple selections only
      // Supports disallow remove selected option with "locked" in multiselection field
      if (newOptions && newOptions.length > 0 && typeof newOptions[0] === 'object') {
        const lockedOptions = newOptions.filter((option) => option.locked); // Obj format
        const lockedOptionValues = lockedOptions.map((option) => option.value); // Str format
        // Pass in selected locked option only
        const selectedLockedOptions = lockedOptions
          .filter((option) => value.includes(option.value));
        const selectedLockedOptionValues = lockedOptionValues
          .filter((option) => value.includes(option));

        if (selectedLockedOptions.length > 0) {
          if (newValues.includes(allOption.value)) {
            arrObjects = newOptions.filter((opt) => opt.value !== allOption.value);
            newValues = newOptions
              .filter((opt) => opt.value !== allOption.value).map((opt) => opt.value);
          } else {
            arrObjects = [
              ...selectedLockedOptions,
              ...values.filter((v) => lockedOptionValues.indexOf(v) === -1),
            ];
            newValues = [
              ...selectedLockedOptionValues,
              ...values
                .filter((v) => lockedOptionValues.indexOf(v) === -1)
                .map((v) => v.value || v),
            ];
          }
        } else if (newValues.includes(allOption.value)) {
          // Select all options
          arrObjects = newOptions.filter((opt) => opt.value !== allOption.value);
          newValues = newOptions
            .filter((opt) => opt.value !== allOption.value).map((opt) => opt.value);
        } else {
          arrObjects = newOptions.filter((option) => newValues.includes(option.value || option));
        }
      }

      // Support object values (arrObjects) and string values
      onChange(e, newValues || [], arrObjects);
      return;
    }

    let object = { label: '', value: '' };
    if (newOptions && newOptions.length > 0 && typeof newOptions[0] === 'object') {
      const hasDuplicates = newOptions
        .some((opt, index) => newOptions.findIndex((o) => o.value === opt.value) !== index);

      let index;
      if (hasDuplicates) {
        index = newOptions.findIndex((opt) => opt.id === (values && values.id));
      } else {
        index = newOptions.findIndex((opt) => opt.value === ((values && values.value) || values));
      }

      if (index > -1) {
        object = newOptions[index];
      }
    }
    onChange(e, ((values && values.value) || values), object);
  };

  const getOptionSelected = (option, selected) => {
    const optionValue = (option.value || option.label) || option;
    const selectedValue = (selected && selected.value) || selected;
    return selectedValue === optionValue;
  };

  const renderStringOnly = (v) => {
    if (v) {
      return typeof v === 'string' ? v : '';
    }
    return '';
  };

  const getOptionLabel = (selected) => {
    if (selected.label === 'Load more' && selected.isLoadMore) {
      return ''; // Return an empty string or null to hide the "Load more" label
    }
    const emptyLabel = '[No label]';
    // Is affecting the input selection and option listing
    if (selected.label) {
      return renderStringOnly(selected.label) || emptyLabel;
    }

    const index = newOptions.findIndex((option) => (option.value || option) === selected);
    if (index > -1) {
      if (optionLabelName
        && Object.prototype.hasOwnProperty.call(newOptions[index], optionLabelName)) {
        return renderStringOnly(newOptions[index][optionLabelName]) || emptyLabel;
      }
      return (
        renderStringOnly(newOptions[index])
        || renderStringOnly(newOptions[index].label)
        || emptyLabel);
    }
    return renderStringOnly(selected) || emptyLabel;
  };

  const getValue = () => {
    if (value && !isEmpty(value) && !isEmpty(newOptions)) {
      return value;
    }
    if (autocompleteProps.multiple) {
      return value || [];
    }
    return null;
  };

  const renderPlaceholder = () => {
    if (autocompleteProps.renderTags === null) {
      return '';
    }
    return placeholder;
  };

  return (
    <Autocomplete
      ref={anchorRef}
      classes={{
        root: classes.root,
        paper: classes.paper,
        listbox: classes.listbox,
      }}
      noOptionsText="No results found"
      options={newOptions}
      value={getValue()}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={(option) => option.locked || option.disabled}
      getOptionSelected={getOptionSelected}
      renderTags={(tagValue, getTagProps) => (
        Array.isArray(tagValue) && !isEmptyValue(tagValue) && tagValue.map((v, index) => {
          let valueInfo = {
            label: '',
            value: '',
            locked: false,
          };
          if (typeof v === 'object') {
            valueInfo = v;
          } else if (newOptions.findIndex((opt) => (opt.value || opt) === v) > -1) {
            valueInfo = newOptions.find((opt) => (opt.value || opt) === v);
          }
          return (
            <>
              {autocompleteProps.customTagRender
                ? autocompleteProps.customTagRender((valueInfo && valueInfo.label) || v || '')
                : (
                  <Fragment key={(valueInfo && valueInfo.value) || v || ''}>
                    <Chip
                      label={(valueInfo && valueInfo.label) || v || ''}
                      {...getTagProps({ index })}
                      disabled={(valueInfo && valueInfo.locked) || autocompleteProps.disabled}
                    />
                    { tagValue.length - 1 > index && textBetweenTags ? <Box ml={0.5} mr={0.5}>{ textBetweenTags }</Box> : '' }
                  </Fragment>
                )}
            </>
          );
        })
      )}
      {
        ...(type === 'checkbox' && autocompleteProps.multiple
          ? {
            renderOption: (option) => (
              <>
                <Checkbox
                  className={classes.checkbox}
                  checked={
                    (!isEmptyValue(getValue()) && getValue().includes(option.value))
                    || (
                      isSelectAllCheckbox
                      && option.value === allOption.value
                      && !isEmptyValue(getValue())
                      && getValue().length === newOptions.length - 1
                    )
                  }
                />
                <Typography className={classes.checkboxLabel}>
                  { option.label }
                </Typography>
              </>
            ),
          }
          : {})
      }
      onChange={(e, v, r) => handleOnChange(e, v, r)}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          error={!!error}
          required={required}
          variant="outlined"
          label={label}
          placeholder={renderPlaceholder()}
          className={classes.textField}
          helperText={!hideErrorText && !!error ? error : null}
          InputLabelProps={{
            shrink: false,
            variant: 'standard',
            disableAnimation: true,
          }}
        />
      )}
      {...autocompleteProps}
    />
  );
}

Searchable.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  textBetweenTags: PropTypes.string,
  type: PropTypes.string,
  optionLabelName: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  anchorRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  required: PropTypes.bool,
  hideErrorText: PropTypes.bool,
  customTags: PropTypes.bool,
  addSelectAllOption: PropTypes.bool,
  enableLoadMore: PropTypes.bool,
  onChange: PropTypes.func,
};

Searchable.defaultProps = {
  label: '',
  error: '',
  placeholder: 'Search',
  textBetweenTags: '',
  type: '',
  optionLabelName: '',
  options: [],
  value: null,
  anchorRef: null,
  required: false,
  hideErrorText: false,
  customTags: false,
  addSelectAllOption: false,
  enableLoadMore: false,
  onChange: () => {},
};
