import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Checkbox,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { RemoveCircle } from '@material-ui/icons';
import { CalendarIcon } from '../../../../assets/icons';
import {
  AddButton,
  Drawer,
  SelectInput,
  DatePicker,
} from '../../../../components';
import { Searchable, SearchableProject } from '../../../../components/Dropdown';
import { PageContext, AuthContext } from '../../../../contexts';
import { countries } from '../../../../data';
import useStyles from './styles';
import { clientCustomer } from '../../../..';
import {
  CUSTOMER,
} from '../../../../graphql';
import {
  useEnhancedQuery,
} from '../../../../hooks';
import { getFieldLabel } from '../../../../utils';

export default function AddAudience(props) {
  const {
    data,
    open,
    isEdit,
    isLoading,
    onClose,
    onSubmit,
  } = props;

  const pageContext = useContext(PageContext);
  const {
    leadFieldSettings,
    campaignOptions,
    projects,
    campaigns,
  } = pageContext.state;

  const classes = useStyles(props);

  const [audience, setAudience] = useState({ category: 'customer', ...data });
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [projectOptions, setProjectOptions] = useState([]);
  const [isCampaignProjects, setIsCampaignProjects] = useState(false);

  const authContext = useContext(AuthContext);
  const { userOptions } = authContext.state;

  const isRulesFilled = () => {
    if (audience.conditions && audience.conditions.length > 0) {
      const haveValues = (obj, type) => (
        Object.prototype.hasOwnProperty.call(obj, type) && obj[type]
      );
      const allHaveField = audience.conditions.every((obj) => haveValues(obj, 'field'));
      const allHaveOperator = audience.conditions.every((obj) => haveValues(obj, 'operator'));
      const allHaveValue = audience.conditions.every((obj) => haveValues(obj, 'value'));

      if (allHaveField && allHaveOperator && allHaveValue) {
        return true;
      }
    }
    return false;
  };
  const incompleteForm = !audience.name || !audience.conditions || !isRulesFilled();
  const disableSubmit = incompleteForm || !agreeTerms || isLoading;

  const [daterangeFields] = useState([
    'created_at',
    'imported_at',
    'srb_created_at',
    'updated_at',
  ]);
  const [pastImportsOption, setPastImportsOption] = useState([]);
  useEffect(() => {
    if (data) {
      setAudience({
        category: 'customer',
        ...data,
      });
    }
  }, [data]);

  useEffect(() => {
    if (!open) {
      if (isEdit) {
        setAgreeTerms(true);
      } else {
        setProjectOptions([]);
        setAgreeTerms(false);
      }
      setAudience({
        category: 'customer',
        ...data,
      });
    }
  }, [
    data,
    open,
    isEdit,
  ]);

  // Get project list based on selected campaigns
  const getProjectList = useCallback((newCampaignIDs) => {
    const selectedCampaignProjects = [];
    if (newCampaignIDs && newCampaignIDs.length > 0) {
      newCampaignIDs.forEach((id) => {
        if (campaigns[id] && campaigns[id].project_ids) {
          campaigns[id].project_ids.forEach((i) => {
            const isExists = selectedCampaignProjects.findIndex((project) => (
              project.value === i
            ));
            if (isExists === -1) {
              selectedCampaignProjects.push({
                label: (projects[i] && projects[i].name) || id,
                value: i,
              });
            }
          });
        }
      });
    }
    return selectedCampaignProjects;
  }, [
    campaigns,
    projects,
  ]);

  useEffect(() => {
    async function getCampaignProjects() {
      let newProjectList = [];
      const campaignIndex = audience.conditions.findIndex((condition) => condition.field === 'campaign_ids');

      // Return project options that ties to the campaign
      if (campaignIndex > -1 && audience.conditions[campaignIndex].value) {
        setIsCampaignProjects(true);
        const { value } = audience.conditions[campaignIndex];
        const campaignIDs = value.split(',');
        newProjectList = getProjectList(campaignIDs);
      } else {
        setIsCampaignProjects(false);
        newProjectList = [];
      }
      setProjectOptions(newProjectList);
    }

    if (audience.conditions && audience.conditions.length > 0) {
      getCampaignProjects();
    }
  }, [
    getProjectList,
    audience.conditions,
  ]);

  // TODO: need to support dynamic limit
  const queryListPastImports = useEnhancedQuery(CUSTOMER.LIST_CUSTOMER_UPLOADS(), {
    options: {
      client: clientCustomer,
      variables: {},
    },
    defaultData: {
      items: [],
      count: 0,
    },
    selector: (d) => d.listCustomerUploads,
  });

  useEffect(() => {
    if (!queryListPastImports.loading && !queryListPastImports.error) {
      if (queryListPastImports.data && queryListPastImports.data.items) {
        let importsList = [];
        const importOptions = [];
        importsList = queryListPastImports.data.items;
        importsList.forEach((imports) => {
          importOptions.push({ label: imports.filename, value: imports.import_id });
        });
        setPastImportsOption(importOptions);
      } else {
        setPastImportsOption([]);
      }
    }
  }, [
    queryListPastImports.data,
    queryListPastImports.loading, queryListPastImports.error,
  ]);

  // Convert array to string when submit data
  const joinArray = (array) => {
    if (array && array.length > 0) {
      return array.join(',');
    }
    return '';
  };

  // Convert string to array for multi selection component
  const splitString = (string) => {
    if (string) {
      return string.split(',');
    }
    return [];
  };

  const handleClose = () => {
    onClose();
  };

  const handleChangeName = (e) => {
    const { value } = e.target;
    setAudience((prev) => ({
      ...prev,
      name: value,
    }));
  };

  const getRemovedProjectAssignment = (items) => {
    // Remove selected project field value
    const haveProjects = items.conditions.findIndex((condition) => condition.field === 'project_ids') > -1;
    if (haveProjects) {
      const newProjectRules = [];
      items.conditions.forEach((condition) => {
        if (condition.field === 'project_ids' && condition.value) {
          newProjectRules.push({
            ...condition,
            value: null,
          });
        } else {
          newProjectRules.push(condition);
        }
      });
      return ({
        ...items,
        conditions: [...newProjectRules],
      });
    }
    return ({ ...items });
  };

  const handleRemoveRule = (index) => {
    let newAudience = JSON.parse(JSON.stringify(audience));
    const conditions = newAudience.conditions ? [...newAudience.conditions] : [];
    conditions.splice(index, 1);
    newAudience.conditions = conditions;

    if (audience.conditions[index].field === 'campaign_ids') {
      newAudience = getRemovedProjectAssignment(newAudience);
    }

    setAudience({ ...newAudience });
  };

  const handleAddRule = () => {
    const newAudience = JSON.parse(JSON.stringify(audience));
    newAudience.conditions = (newAudience.conditions || []).concat({});
    setAudience({ ...newAudience });
  };

  const handleCloseDatePicker = (single, value, index, type) => {
    const newAudience = JSON.parse(JSON.stringify(audience));
    const conditions = newAudience.conditions ? [...newAudience.conditions] : [];

    if (type === 'value'
      && !single
      && daterangeFields.includes(conditions[index].field)
      && !(value.start && value.end)) {
      delete conditions[index][type];
      newAudience.conditions = conditions;
      setAudience({ ...newAudience });
    }
  };

  const handleChangeCondition = (value, index, type) => {
    let newAudience = JSON.parse(JSON.stringify(audience));
    const conditions = newAudience.conditions ? [...newAudience.conditions] : [];

    if (conditions[index]) {
      // Remove these conditions cause selected values might not be relevant
      if (['field', 'operator'].includes(type)) {
        if (conditions[index].value) {
          delete conditions[index].value;
        }
        if (type === 'field' && conditions[index].operator) {
          delete conditions[index].operator;
        }
      }
    }

    const isArray = type === 'value' && conditions[index] && ['campaign_ids', 'project_ids', 'buyer_access_project_ids'].includes(conditions[index].field);
    conditions[index] = {
      ...conditions[index],
      [type]: isArray ? joinArray(value) : value,
    };
    newAudience.conditions = conditions;

    if (audience.conditions[index] && audience.conditions[index].field === 'campaign_ids') {
      // Remove audience project value if its campaign got removed
      if (type === 'value' && audience.conditions[index].value !== joinArray(value)) {
        const projectConditionIndex = newAudience.conditions.findIndex((condition) => condition.field === 'project_ids');
        if (projectConditionIndex > -1 && newAudience.conditions[projectConditionIndex].value) {
          const prevProjectSelections = splitString(
            newAudience.conditions[projectConditionIndex].value,
          );
          const newProjectSelections = [];
          prevProjectSelections.forEach((id) => {
            const isExists = getProjectList(value)
              .findIndex((project) => project.value === id) > -1;
            if (isExists) {
              newProjectSelections.push(id);
            }
          });
          newAudience.conditions[projectConditionIndex].value = joinArray(newProjectSelections);
        }
      }

      // Remove audience project value if got change campaign field to another field
      // Remove audience project value if clear campaign
      if ((type === 'field' && value !== 'campaign_ids')
        || (type === 'value' && _.isEmpty(value))
      ) {
        newAudience = getRemovedProjectAssignment(newAudience);
      }
    }

    if (type === 'value') {
      if (['imported_by', 'updated_by', 'import_id'].includes(conditions[index].field) && value) {
        conditions[index].value = value;
      } else if (daterangeFields.includes(conditions[index].field)
      && conditions[index].operator === '<>' && value) {
        conditions[index].value = joinArray([value.start, value.end]);
      } else if (conditions[index].field === 'date_of_birth' && value) {
        conditions[index].value = value.format('YYYY-MM-DD');
      }
    }

    setAudience({ ...newAudience });
  };

  const handleSubmit = (e) => {
    if (!disableSubmit) {
      onSubmit(e, { ...audience });
    }
  };

  const getFieldSettingOptions = (type, name) => ((
    leadFieldSettings[type]
    && leadFieldSettings[type].attributes
    && leadFieldSettings[type].attributes[name]
    && leadFieldSettings[type].attributes[name].options
  ) || []);

  const renderConditionSeparator = (index) => {
    if (index > 0) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.separator}
        >
          <Typography variant="caption" className={classes.andWrapper}>
            AND
          </Typography>
        </Grid>
      );
    }
    return null;
  };

  const renderDatePicker = (condition, index) => {
    let dateValue = condition.value;
    const isSingleSelection = condition && condition.operator !== '<>';
    if (condition && condition.operator === '<>') {
      const renderDate = (condition.value && splitString(condition.value)) || [];
      dateValue = { start: (renderDate.length === 2 && renderDate[0]) || '', end: renderDate[1] || '' };
    }
    return (
      <DatePicker
        disableClearable
        simple={isSingleSelection}
        inputVariant="outlined"
        disabled={!condition.field || isLoading}
        value={dateValue || null}
        onClose={() => handleCloseDatePicker(isSingleSelection, dateValue, index, 'value')}
        onChange={(value) => handleChangeCondition(value, index, 'value')}
      />
    );
  };

  const renderValueField = (condition, options, index) => {
    const newOptions = condition.field && options[condition.field] ? options[condition.field] : [];
    if (!['srb_created_at', 'date_of_birth', 'created_at', 'imported_at'].includes(condition.field) && condition.value) {
      // Append the value into the options if dont have so the chip wont appear blank
      // User wont be able to select again once delete the selection
      if (['project_ids', 'campaign_ids'].includes(condition.field)) {
        const arrayValues = splitString(condition.value);
        arrayValues.forEach((value) => {
          const isExists = newOptions.filter((option) => value === option.value).length > 0;
          if (!isExists) {
            newOptions.push({
              label: value,
              value,
            });
          }
        });
      } else {
        const isExists = newOptions.filter((option) => condition.value === option.value).length > 0;
        if (!isExists) {
          newOptions.push({
            label: condition.value,
            value: condition.value,
          });
        }
      }
    }

    switch (condition.field) {
      case 'srb_created_at':
      case 'imported_at':
      case 'created_at':
      case 'updated_at':
        return renderDatePicker(condition, index);
      case 'date_of_birth':
        return (
          <KeyboardDatePicker
            fullWidth
            inputVariant="outlined"
            format="MMM"
            views={['month']}
            value={condition.value || null}
            disabled={!condition.field || isLoading}
            onChange={(value) => handleChangeCondition(value, index, 'value')}
            InputLabelProps={{
              shrink: false,
              variant: 'standard',
              disableAnimation: true,
            }}
            keyboardIcon={(
              <Icon className={classes.calendarIcon}>
                <img alt="date" src={CalendarIcon} />
              </Icon>
            )}
            KeyboardButtonProps={{
              classes: {
                root: classes.datePickerRoot,
              },
            }}
          />
        );
      case 'project_ids':
      case 'buyer_access_project_ids':
        return (
          <SearchableProject
            multiple
            filterSelectedOptions
            placeholder="Choose a value"
            textBetweenTags="OR"
            disabled={!condition.field || isLoading}
            isParentOptions={isCampaignProjects}
            parentOptions={newOptions}
            value={
              newOptions.length > 0
                ? splitString(condition.value)
                : []
            }
            onChange={(e, value) => handleChangeCondition(value, index, 'value')}
          />
        );
      case 'campaign_ids':
        return (
          <Searchable
            multiple
            filterSelectedOptions
            placeholder="Choose a value"
            textBetweenTags="OR"
            disabled={!condition.field || isLoading}
            options={newOptions}
            value={
              newOptions.length > 0
                ? splitString(condition.value)
                : null
            }
            onChange={(e, value) => handleChangeCondition(value, index, 'value')}
          />
        );
      case 'imported_by':
      case 'updated_by':
      case 'import_id':
        return (
          <Searchable
            placeholder="Choose a value"
            disabled={!condition.field || isLoading}
            options={newOptions}
            value={newOptions.length > 0 ? condition.value : null}
            onChange={(e, value) => handleChangeCondition(value, index, 'value')}
          />
        );
      default:
        return (
          <SelectInput
            placeholder="Choose a value"
            disabled={!condition.field || isLoading}
            options={newOptions}
            value={
              newOptions.length > 0
                ? condition.value
                : null
            }
            onChange={(e, value) => handleChangeCondition(value, index, 'value')}
          />
        );
    }
  };

  const renderCondition = (condition, index) => {
    let fieldOptions = [];
    let operatorOptions = [];
    const valueOptions = {
      bumi: getFieldSettingOptions('basic', 'bumi'),
      buyer_type: getFieldSettingOptions('sales', 'buyer_type'),
      campaign_ids: [...campaignOptions],
      country_code: [...countries],
      gender: getFieldSettingOptions('basic', 'gender'),
      nationality_country_code: [...countries],
      project_ids: projectOptions,
      race: getFieldSettingOptions('basic', 'race'),
      source: getFieldSettingOptions('sales', 'source'),
      title: getFieldSettingOptions('basic', 'title'),
      imported_by: [...userOptions],
      updated_by: [...userOptions],
      import_id: pastImportsOption,
      buyer_access_project_ids: projectOptions,
    };

    fieldOptions = [
      'created_at',
      'bumi',
      'buyer_type',
      'campaign_ids',
      'country_code',
      'date_of_birth',
      'gender',
      'import_id',
      'imported_at',
      'imported_by',
      'nationality_country_code',
      'project_ids',
      'race',
      'source',
      'srb_created_at',
      'buyer_access_project_ids',
      'title',
      'updated_at',
      'updated_by',
    ].map((key) => ({ label: getFieldLabel(key), value: key }));

    operatorOptions = [
      { label: 'is', value: '==' },
    ];
    if (condition.field !== 'buyer_access_project_ids') {
      operatorOptions.push({ label: 'is not', value: '!=' });
    }
    if (condition && daterangeFields.includes(condition.field)) {
      operatorOptions = [
        { label: 'is', value: '==' },
        { label: 'is not', value: '!=' },
        { label: 'is between', value: '<>' },
      ];
    }
    // Remove selected field option
    if (audience.conditions && audience.conditions.length > 0) {
      const selected = audience.conditions.map((c) => c.field).filter((c) => c !== condition.field);
      fieldOptions = fieldOptions.filter((option) => !selected.includes(option.value));
    }

    return (
      <div className={classes.wrapper}>
        <Grid
          container
          alignItems="center"
          className={classes.rule}
        >
          <Grid item>
            <Typography>If</Typography>
          </Grid>
          <Grid item className={classes.fields}>
            <div className={classes.dropdown}>
              <SelectInput
                placeholder="Select a field"
                disabled={isLoading}
                options={fieldOptions}
                value={condition.field}
                onChange={(e, value) => handleChangeCondition(value, index, 'field')}
              />
            </div>
            <div className={classes.dropdown}>
              <SelectInput
                placeholder="Select a field"
                disabled={isLoading}
                options={operatorOptions}
                value={condition.operator}
                onChange={(e, value) => handleChangeCondition(value, index, 'operator')}
              />
            </div>
            <div className={classes.dropdown}>
              { renderValueField(condition, valueOptions, index) }
            </div>
          </Grid>
          <Grid item>
            <IconButton
              className={classes.removeFieldIcon}
              disabled={isLoading}
              onClick={() => handleRemoveRule(index)}
            >
              <RemoveCircle />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderTerms = () => {
    if (!isEdit) {
      return (
        <Grid container alignItems="flex-start" wrap="nowrap" className={classes.terms}>
          <Checkbox
            name="terms_and_conditions"
            onChange={() => setAgreeTerms((prev) => !prev)}
          />
          <Typography variant="body2">
            I have received permission from these customers to communicate with
            them via email or SMS.
            All subscribed customers within this group want to receive marketing campaigns from me.
            This is required in the&nbsp;
            <Link target="_blank" rel="noopener" href="https://support.mhub.my/hc/en-us/articles/1500002366301-Email-and-SMS-Blast-Terms-of-Use">
              Terms of Use.
            </Link>
          </Typography>

        </Grid>
      );
    }
    return null;
  };

  const haveConditions = audience.conditions && audience.conditions.length > 0;
  return (
    <Drawer
      isWideDrawer
      open={open}
      header={`${isEdit ? 'Edit' : 'New'} audience`}
      submitLabel={isEdit ? 'Save' : 'Create'}
      isSubmitDisabled={disableSubmit}
      onClose={handleClose}
      onSubmit={handleSubmit}
    >
      <div className={classes.container}>
        <Typography variant="body2">
          Group audiences by rule configuration. For example,
          you can group customers by certain demographics like nationality or gender.&nbsp;
          <Link target="_blank" rel="noopener" href="https://support.mhub.my/hc/en-us/articles/360062778373-What-is-an-audience-group-">
            Learn more
          </Link>
        </Typography>

        <TextField
          fullWidth
          name="name"
          label="Audience name"
          variant="outlined"
          defaultValue={audience.name}
          disabled={isLoading}
          InputLabelProps={{
            shrink: false,
            variant: 'standard',
            disableAnimation: true,
          }}
          onChange={handleChangeName}
        />

        <InputLabel>
          Include customers who meet all of the following rule criteria
        </InputLabel>
        {
          haveConditions && (
            React.Children.toArray(audience.conditions.map((condition, idx) => (
              <>
                { renderConditionSeparator(idx) }
                { renderCondition(condition, idx) }
              </>
            )))
          )
        }
        <Grid>
          <AddButton
            className={classes.addButton}
            label="Add rule"
            disabled={isLoading}
            onClick={handleAddRule}
          />
        </Grid>
        { renderTerms() }
      </div>
    </Drawer>
  );
}

AddAudience.propTypes = {
  data: PropTypes.instanceOf(Object),
  open: PropTypes.bool,
  isEdit: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

AddAudience.defaultProps = {
  data: {},
  open: false,
  isEdit: false,
  isLoading: false,
  onClose: () => {},
  onSubmit: () => {},
};
